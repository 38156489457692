.invoice-container,
.invoice-container li {
  list-style: none;
  padding: 0;
}

.amount-link {
  /* text-decoration: underline !important; */
  cursor: pointer !important;
}

.snapshot-carousel .carousel-item h3 {
  width: 96%;
  padding-bottom: 16px;
  text-align: center;
}

.el-picker-panel>>>.current {
  background-color: unset !important;
}

.m-portlet .m-portlet__head {
  height: 4.1rem;
}

.chart-header {
  padding: 2rem 0 1.5rem 0;
  font-size: 1.3rem;
  font-weight: 500;
  font-family: Roboto;
  /* border-bottom: 1px solid #ebedf2; */
}

.positive {
  color: #34bfa3 !important;
}

.negative {
  color: #f4516c !important;
}

.negative>>>.el-input__inner {
  color: #f4516c !important;
}

.positive>>>.el-input__inner {
  color: #34bfa3 !important;
}

.snapshot-table>>>.cell {
  white-space: nowrap;
}

.snapshot-hours {
  width: 100%;
}

.snapshot-header {
  padding: 0 20px;
}

.min-height-500 {
  min-height: 500px;
  height: 500px;
  max-height: 500px;
}

.arrow-fix.swiper-button-prev,
.arrow-fix.swiper-button-next {
  top: 24px;
  height: 20px;
}

.snapshot-table>>>.el-table__empty-block {
  min-height: 500px;
}

.snapshot-table>>>.el-table__row td:nth-child(4) .el-input__inner {
  text-align: end;
}

.snapshot-table>>>.el-table__row td:nth-child(5) {
  text-align: end;
}

.cumulative-field {
  text-align: end;
}

.remove-snapshot-accrual>>>.el-dialog__body {
  padding-bottom: 0px;
}

/* .snapshot-hours-col input {
  text-align: right !important;
  padding-right: 15px !important;
} */

.snapshot-table>>>.el-table__row td:nth-child(2) .el-input__inner {
  text-align: end;
  padding-right: 15px !important;
}

.snapshot-table {
  border-right: 1px solid #ebeef5 !important;
}

.el-swiper-container {
  position: relative;
  margin-bottom: 5px
}

.el-button.left {
  left: 0px;
  z-index: 80;
  position: absolute;
  background-color: #5867dd;
  border-color: #5867dd;
  color: #ffffff;
}

.el-button.right {
  right: 0px;
  z-index: 80;
  position: absolute;
  background-color: #5867dd;
  border-color: #5867dd;
  color: #ffffff;
}

.el-button.right.is-disabled {
  opacity: 0.5;
}

.snapshot-table>>>.el-table__body-wrapper {
  height: auto !important;
}

.el-swiper-container h3 {
  text-align: center;
}

.snapshot-table>>>.readonly-row {
  background-color: #ffffff;
  pointer-events: none;
}

.card_block {
  background-color: #5867dd;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
}

.snapshot-table>>>.table_footer_td {
  font-weight: bold;
  text-align: right;
}

.snapshot-table>>>.table_footer_td i {
  padding-left: 3px;
}

.snapshot-table>>>.table_footer_td i::before {
  font-weight: bold;
}

.snapshot-table>>>.table_footer_profit {
  color: #34bfa3 !important
}

.snapshot-table>>>.table_footer_loss {
  color: #f4516c !important
}