#talent_forecast_widget_chart{
  height: 175px !important;
}
.amcharts-main-div {
  width: 100%;
  height: 100%;
}

/*override the metronic dynamic styling for the dashboard project listing*/
#dashboard_widgets_vue .m-widget1__item {
  border-bottom: 0.07rem dashed #ebedf2 !important;}


.forecast-widget-no-data {
  color: #575962 !important;
  background-color: white !important;
  margin-left: 10px;
  margin-top: 80px;
}
