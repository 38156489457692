tr.need-analysis-summary-header-row > th {
  text-align: center !important;
  background-color: #f5f7fa;
}
/*.el-table .danger-row {
 background: #fef0f0;
 }
 .el-table .warning-row {
 background: #fdf6ec;
 }
 .el-table .success-row {
 background: #f0f9eb;
 }*/
