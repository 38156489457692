/* add comma after multiple managers */
.managers span:not(:first-child):before{
	content: ", ";
}

/* remove extra space before the comma */
.managers span{
	margin-left: -0.2em;
}

#project_summary_panel{
  box-shadow: 0px 1px 15px 1px rgba(81, 77, 92, 0.08);
}
