.invoice-table .el-date-editor.el-input,
.invoice-table .el-date-editor.el-input__inner {
  width: 100%;
}
.button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    margin-top: -30px;
}
.invoice-table >>> .el-table__row .cell {
  text-overflow: unset;
}

.invoice-table>>>.el-table__row td:nth-child(3) .el-input__inner {
  text-align: end;
}
