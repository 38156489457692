/* overriding css of metronic widget */

#critical_date_container .m-timeline-2__items .m-timeline-2__item:not(:first-child) {
  margin-top: 30px !important;
}

#critical_date_container .m-timeline-2 .m-timeline-2__items .m-timeline-2__item .m-timeline-2__item-cricle {
  left: 7.1rem;
}

#critical_date_container .m-timeline-2 .m-timeline-2__items .m-timeline-2__item .m-timeline-2__item-text {
  padding-left: 7.5rem;
}

#critical_date_container .m-timeline-2 {
  display: flex;
}

#critical_date_container .m-timeline-2:before {
  content: '';
  position: relative;
  width: 0.214rem;
  left: 8.03rem;
  height: auto;
}

.no-data-handler {
	position: relative;
	top: 50%;
	text-align: center;
}