.el-picker-panel__sidebar {
  width: 125px;
}
.m-widget1.font-weight-overide .m-widget1__item .m-widget1__number {
  font-weight: 300;
}
.pad-0 {
  padding: 0;
}
g[aria-labelledby$="-title"][filter^="url(\"#filter-id-"] {
  opacity: 0;
  display: none;
  visibility: hidden;
}

.no-data:after{
  display: flex;
  position: absolute;
  content: "- No Data -";
  background: rgba(255, 255, 255, .9);
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 1;
  align-items: center;
  justify-content: center;
}
.detailed .m-portlet__head {
  /* border-bottom : 0px; */
}
.detailed .m-portlet__body {
  padding-top: 0rem;
}
.font-size-override .m-widget1__item .m-widget1__number,
.font-size-override .m-widget1__item .m-widget1__title {
  font-size: 1rem;
}
.pad-0 {
  padding: 0
}
.pull-right {
  float: right;
}
.margin-top-52 {
  margin-top: -52px;
}
.el-select-dropdown__item.is-disabled {
  pointer-events: none;
}

.font-bold-500 {
  font-weight: 500 !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.blur {
  opacity: 0.3;
}
.width-150 {
  width: 125px !important;
}
.copy-btn {
  width: 33px;
  height: 33px;
  margin-right: 12px;
}

.copy-btn i {
  font-size: 1.3rem;
  color: #36a3f7;
}
.copy-btn:hover i {
  color: #ffffff;
}

.m-link.link-table:hover:after {
  border-bottom: none;
}

/* Start: Charts global CSS  */
.amcharts-axis-band,
.amcharts-graph-graphs0 .amcharts-graph-column-element,
.amcharts-graph-graphs1 .amcharts-graph-column-element {
   -webkit-transition: all .5s ease-out;
  transition: all .5s ease-out;
  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s; /* Opera */
}
/* .amcharts-graph-graphs0 .amcharts-graph-column-element:hover,
.amcharts-graph-graphs1 .amcharts-graph-column-element:hover {
  stroke: #aa00ff; 
  stroke-width: 5px;
  stroke-linecap: round;
} */
@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}

/* End: Charts global CSS  */

.select-filter__wrap .el-tag--small{
    display: flex;
    align-items: center;
}

.select-filter__wrap .el-select__tags-text{
    max-width: 196px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.custom-margin {
  margin-bottom: 1.80rem; 
}
.custom_summary_border{
  border-bottom: 1px solid #ebedf2;
}