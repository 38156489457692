.m-widget27
  .m-widget27__container
  .m-widget27__nav-items
  .m-widget27__nav-item
  > a.active {
  background-color: #716aca;
  border-color: #716aca !important;
}

.reverse-table .vxe-body--row .vxe-body--column:first-child {
  background-color: #f8f8f9;
}

.reverse-table{
	margin-bottom: 10px;
}

.m-nav__section-text{
  margin-top: 5px;
}

.year-picker-selector {
    width: 120px !important;
}

.custom-height-empty-placeholder >>> .vxe-table.is--empty .vxe-table--empty-placeholder {
  height: 200px !important;
  position: relative;
}
