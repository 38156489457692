.br-2rem>>>.el-input__inner {
  border-radius: 2rem;
  width: 188px;
}


.m-calendar-fix>>>.el-input__inner,
.m-calendar-fix>>>.el-icon-date {
  color: #716aca;
}

.m-calendar-grey-bg >>> .el-input__inner {
  background: #f7f7fb;
}