#yearly_accruals_chart {
	width: 100%;
	height: 500px;
	font-size: 11px;
}
.accrual-widgets{
	/* padding: 1.2em; */
	border: 1px solid #ccc;
}
.mt-20{
	margin-top: 20px;
}
.accrual-widget-img {
	height: 150px;
}
.no-padding {
	margin-bottom: 0px !important;
}

.br-2rem{
	border-radius: 2rem;
}

.project_cards {
	height: 425px;
	overflow: scroll;
}
.pm-selector {
	padding: 20px 0 0 0;
	display: flex;
	justify-content: flex-end;
	padding-right: 15px;
}

.pm-selector .el-button{
	margin-right: 15px;
}
.date-picker-alignment {
	padding-top: 8px;
	position: absolute;
	left: 86%;
}

.el-selector-filter-fix {
	width: 180px;
	padding-top: 8px;
	position: absolute;
	left: 85%;
}

.el-selector-filter-fix >>> .el-input__inner {
	background: white !important;
	border: 1px solid #dcdfe6 !important;
}

.copy-to-clipboard {
	position: absolute;
	right: 17%;
	top: 22%;
}

.pm-selector >>> .select-filter__wrap {
	/* width: 70%;
	position: absolute;
	right: 15px; */
}

.el-month-table >>> .current {
	background-color: #409EFF !important;
	color: white !important;
}

.quarter-picker {
	padding-top: 8px;
	position: absolute;
	left: 83%;
}

.pm-s {
	/* position: absolute;
	right: 21%; */
}

.year-picker-selector {
	/* left: 78%;
	position: absolute; */
}

.year-picker-selector >>> .el-input__inner {
	background: #f7f7fb;
	width: 100%;
	border: #fff !important;
	padding-right: 22px;
}

.year-picker-selector >>> .el-date-editor--year {
	width: 80% !important;
}

.quarter-picker >>> .el-input__inner {
	background: #fff !important;
	border: 1px solid #dcdfe6 !important;
}

.no-data:after {
  display: flex;
  position: absolute;
  content: "- No Data -";
  background: rgba(255, 255, 255, .9);
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 3 !important;
  align-items: center;
  justify-content: center;
}

.project-list {
	padding-left: 0;
	padding-right: 0;
}

.project-month-picker >>> .el-date-editor.el-input,
.project-month-picker >>> .el-date-editor.el-input__inner {
	width: 100%;
}

.metronic-btn {
	background-color: #5867dd !important;
	border-color: #5867dd !important;
}