.breadcrumb-fix{
  padding-top: 10px;
}
.el-selector-fix {
  padding-top: 20px;
}

.el-selector-fix >>> .el-date-editor.el-input,
.el-selector-fix >>> .el-date-editor.el-input__inner {
  width: 100%;
}

.edit-acc {
  display: flex;
    justify-content: flex-end;
}

.edit-acc button {
  margin-right: 20px;
}

.metronic-btn {
	background-color: #5867dd !important;
	border-color: #5867dd !important;
}

.export-snapshot {
  margin-right: 17px;
    margin-top: 2px;
}

.pad-right {
  padding-right: 15px !important;
}