.skill_set_dialog .btn {
    margin-right: 5px;
    margin-bottom: 15px;
  }
  
  .el-form-item-override >>> .el-form-item__label {
    line-height: 0px !important;
    padding-top: 12px !important;
  }
  
  .el-form-item-override >>> .el-input--medium{
    width: 100% !important;
  }
  
  .el-form-item-override >>> .el-input-number--medium{
    width: 100% !important;
  }
  
  .metronic-btn {
    background-color: #5867dd !important;
    border-color: #5867dd !important;
  }