tr.project-table-header-row > th {
  text-align: center !important;
}
.is-zero {
  color: #ccc;
}
.is-negative {
  color: #f56c6c;
}
.is-positive {
  color: #67c23a;
}
/*Override el-table-column header bg-color with important*/
.el-table thead.is-group th.bg-info {
  background-color: #e0ebf9 !important;
}
/*Adding extra margin for switch*/
#dollar_toggler {
  margin-top: 10px;
}
