.el-select>.el-input {
  border-radius: 2rem;
}

.select-filter__wrap .el-tag--small{
  display: flex;
  align-items: center;
}

.select-filter__wrap .el-select__tags-text{
  max-width: 196px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-select>.el-input {
  border-radius: 2rem;
}

.select-filter__wrap >>> .el-input.el-input--medium.el-input--suffix input {
  border-color: #f7f7fb;
  color: #9099b1;
  background: #f7f7fb;
  padding: 0.9rem 1.5rem 0.9rem 1.5rem;
  border-radius: 2rem;
}

.select-filter__wrap >>> .el-input__inner,
.select-filter__wrap >>> .el-icon-arrow-up,
.select-filter__wrap >>> .is-show-close {
  color: #716aca !important;
}