.m-portlet--fullscreen .tasks-table .el-table, .m-portlet--fullscreen .tasks-table .el-table .el-table__body-wrapper {
	height: 100% !important;
}

.m-portlet.tasks-panel.m-portlet--fullscreen {
	z-index: 120;
}

.talent-name-ellipsis {
	overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 220px;
  display: block !important;
}

.vxe-button.size--mini.type--button.is--circle {
  margin-right: 10px;
}
