.burn_report_pop_over{
   z-index: 2 !important;
}
.tab-pane .portlet {
  margin: 0px 20px;
}
.el-date-editor, .el-select {
  width: 100%;
}
/*Overriding default 5% to avoid cutting off the text*/
.el-date-editor .el-range-separator {
  width: 10%;
}
/*Adding extra margin for switch*/
#period_toggler {
  margin-top: 10px;
}
tr.talent-table-header-row > th {
  text-align: center !important;
  background-color: #f5f7fa;
}
.el-floating-button {
  position: absolute;
  right: 45px;
  top: 145px;
  z-index: 2;
}
#project-progress-overview {
  height: 15px;
}
.is-zero {
  color: #ccc;
}
.is-negative {
  color: #f56c6c;
}
.is-positive {
  color: #67c23a;
}
/*Override el-table-column header bg-color with important*/
.el-table thead.is-group th.bg-info {
  background-color: #e0ebf9 !important;
}
.el-table .warning-cell {
  background: oldlace;
}
.el-table .danger-cell {
  background: #fef0f0;
}
