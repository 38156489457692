.parent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: unset;
  margin-right: 0px;
  margin-left: 0px;
  flex-wrap: unset;
  /* overflow-x: scroll; */
}

.scroll-x {
  overflow-x: scroll;
}

.scroll-x .la {
  position: absolute;
  top: 25%;
  font-size: 25px;
}

.scroll-x .la-angle-left {
  left: 20px;
}

.no-data:after {
  display: flex;
  position: absolute;
  content: "- No Data -";
  background: rgba(255, 255, 255, .9);
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 3 !important;
  align-items: center;
  justify-content: center;
}

.min-height {
  height: 100px !important;
}

.scroll-x .la-angle-right {
  right: 20px;
}

.child {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 1 0 25%;
  max-width: 50%;
  min-width: 33.33%;
  /* min-width: 25%; */
}

.summary-card-data {
  padding: 1.2rem 1.2rem !important;
}

.summary-chart {
  padding: 1.2rem !important;
}

.m-text-fix {
  color: #1715AD
}

.m-bg-fix {
  background: #19EFD1;
}

.m-heading-fix {
  color: #040058 !important;
}

.view-project-details {
  font-size: 2.3rem;
  color: white;
  cursor: pointer;
}

.el-selector-filter-fix {
  width: 180px;
  padding-top: 8px;
  position: absolute;
  left: 72%;
}

.el-selector-filter-fix>>>.el-input__inner {
  background: white !important;
  border: 1px solid #dcdfe6 !important;
}

.view-link a {
  text-decoration: none !important;
  color: #040058 !important;
}

.view-link {
  border-color: #040058 !important;
  width: 100%;
  color: #040058;
}

.btn.m-btn--pill.view-link {
  border-radius: 12px;
}

.project-name-truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 220px;
  display: block !important;
}

.parent >>> .swiper-slide {
  margin-right: 0px !important;
}

.parent >>> .swiper-pagination {
  bottom: 0px !important;
}

.parent >>> .swiper-container {
    width: 100%;
}

.m-widget4 .m-widget4__item.overwrite-styles {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.m-widget4 .m-widget4__item .m-widget4__ext .m-widget4__number.override {
  white-space: nowrap;
}
.parent >>> .swiper-button-prev {
	left: 20px !important;
}

.parent >>> .swiper-button-next {
	right: 20px !important;
}