.br-2rem>>>.el-input__inner {
  border-radius: 2rem;
  width: 155px;
}


.m-calendar-fix>>>.el-input__inner,
.m-calendar-fix>>>.el-icon-date {
  color: #716aca;
}
