.forecast-table table tbody td {
  height: 35px;
}
.forecast-table table tbody td.is-zero {
  color: #ccc;
}
.danger-row {
  background-color: #fef0f0;
}
.warning-row {
  background-color: #fdf6ec;
}
.success-row {
  background-color: #f0f9eb;
}

.forecast_table{
  overflow-x: scroll;
  margin-left: 200px;
  overflow-y: visible;
  padding: 0;
}

.forecast_table_container > div > table > thead > tr > th.frozen-col, .forecast_table_container > div > table > tbody > tr > th.frozen-col {
  position: absolute;
  width: 200px;
  background-color: white;
  left: 0px;
  top: auto;
  border: 1px solid #e7ecf1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /*border-top-width: 1px;*/
  /*only relevant for first row*/
  /*margin-top: 1px;*/
  /*compensate for top border*/
  font-weight: 600;
  z-index: 10 !important;
}

div.forecast-chart-controls{
  position: relative;
  top: -64%;
}

div.forecast-bicheck{
  border-bottom: none !important;
  margin: 10px 0px -10px 0px;
}

div.forecast-chart-tabs{
  margin-bottom: -25px;
}


