.chart-container {
  position: relative;
  width: 100%;
  height: 600px;
}

.chartdiv {
  width: 100%;
  height: calc(100% - 30px); 
}

.chart-note {
  text-align: center;
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
  position: absolute;
  width: 100%;
  top: 0;
}

.chart-scrollbar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px; 
  background: transparent;
}
