 /*This file constains the CSS that are overridden / custom written for the element UI */
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner{
  width: 100% !important;
}

/*Overriding default 5% to avoid cutting off the text*/
.el-date-editor .el-range-separator{
  width: 10% !important;
}
/*Overriding default 14px to avoid line break in shortcut buttons*/
.el-picker-panel__shortcut{
  font-size: 13px !important;
}

.el-button i{
  margin: 0px !important;
}