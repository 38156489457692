/*App wide css*/

/* bicheck plugin css */
.bicheck[type="radio"]:checked,
.bicheck[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.bicheck[type="radio"]:checked + label,
.bicheck[type="radio"]:not(:checked) + label
{
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.bicheck[type="radio"]:checked + label:before,
.bicheck[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 4px solid #3598dc;
  border-radius: 100%;
  background: #fff;
}
.bicheck[type="radio"]:checked + label:after,
.bicheck[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 6px;
  height: 6px;
  background: #3598dc;
  position: absolute;
  top: 7px;
  left: 7px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.bicheck[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.bicheck[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.bicheck-inline div:first-child{
  margin-left: 0;
}
.bicheck-inline div{
  margin-left: 15px;
  display: inline-block;
}
/* bicheck plugin css ends*/

/* UI ELEMENT TABLE GLOBAL CSS START*/
.el-table__row.disabled-row {
  background-color: #f2f2f2 !important;
}
/* UI ELEMENT TABLE GLOBAL CSS END*/