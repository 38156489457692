.select-box {
  width: calc(100% - 75px);
  margin-right: 15px;
}

.btn-height {
  line-height: 0.25 !important;
}

.h_scroll::-webkit-scrollbar {
  display: none;
}

.h_scroll {
  -ms-overflow-style: none;
}