/* add comma after multiple managers */
.managers span:not(:first-child):before{
	content: ", ";
}

/* remove extra space before the comma */
.managers span{
	margin-left: -0.2em;
}

#project_summary_panel{
  box-shadow: 0px 1px 15px 1px rgba(81, 77, 92, 0.08);
	width: 100%;
}
.m-widget28 .m-widget28__container .m-widget28__tab .m-widget28__tab-container .m-widget28__tab-items .m-widget28__tab-item > span:last-child {
    color: #7b7e8a;
    font-size: 16px;
    font-weight: 100;
}