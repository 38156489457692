<template>
  <div>
    <div class="m-portlet">

      <!-- <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
          <div class="m-portlet__head-title">
            <h3 class="m-portlet__head-text">
            
            </h3>
          </div>
        </div>
        <div class="m-portlet__head-tools">
          <ul class="m-portlet__nav">
            <li class="m-portlet__nav-item">
              <a class="m-portlet__nav-link btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" @click="ExportSnapshotData" data-toggle="m-tooltip" title="Export">
                <i class="fa fa-file-excel"></i>
              </a>
            </li>
          </ul>
        </div>
      </div> -->
      
      <div class="m-portlet__body">
        <div class="row">
          <!-- v-bind:class="{ 'no-data': !initializeSlider || !accrualsTableData.length }" -->
          <div
            class="col-lg-12 swiper-accrual"
            v-bind:class="{ 'no-data': !loading && !snapshotCurrentData.length }"
            v-loading="loading"
          >
            <el-dialog :visible.sync="accrualDeleteVisible" width="30%" center>
              <span>Do you really want to delete ?</span>
              <span slot="footer" class="dialog-footer">
                <el-button @click="accrualDeleteVisible = false">Cancel</el-button>
                <el-button type="primary" @click="removeLastAccrualMonth()">Confirm</el-button>
              </span>
            </el-dialog>
            <!-- <button type="button" class="btn btn-primary" @click="addMonth()">Add Month</button> -->
            <template v-if="snapshotCurrentData[0]">
              <!-- <swiper
                :options="swiperOption"
                ref="mySwiper"
                @slidePrevTransitionEnd="onSwipePrev"
                @slideNextTransitionEnd="onSwipeNext"
              >
                <swiper-slide
                  :index="index"
                  v-for="(item, index) in accrualsTableData"
                  :key="item.snapshot_date"
              >-->
              <div class="el-swiper-container">
                <el-carousel
                  trigger="click"
                  height="50px"
                  :autoplay="false"
                  ref="snapshot_slider"
                  arrow="never"
                >
                  <el-button class="left" icon="el-icon-arrow-left" circle @click="showPrevData()"></el-button>
                  <el-carousel-item v-for="item in 3" :key="item">
                    <h3>
                      Snapshot for {{snapshotCurrentData[0].snapshot_date | formatSnapshotName('YYYY-MM-DD')}}
                      <span
                        v-if="snapshotCurrentData[0].snapshot_details.snapshot_editable == 0"
                        class="m-badge m-badge--wide m-badge--rounded m-badge--metal"
                      >Closed</span>
                      <span
                        v-if="snapshotCurrentData[0].snapshot_details.snapshot_editable == 1"
                        class="m-badge m-badge--wide m-badge--rounded m-badge--info"
                      >Open</span>
                    </h3>
                  </el-carousel-item>
                  <el-button
                    :disabled="isFutureWeek"
                    class="right"
                    icon="el-icon-arrow-right"
                    circle
                    @click="showNextData()"
                  ></el-button>
                </el-carousel>
              </div>

              <!-- <i class="el-icon-arrow-left" @click="showPrevData()"></i>
              <h3 style="text-align: center;">
                Snapshot for {{snapshotCurrentData[0].snapshot_date | formatSnapshotName('YYYY-MM-DD')}}
                <span
                  v-if="snapshotCurrentData[0].snapshot_details.snapshot_editable == 0"
                  class="m-badge m-badge--wide m-badge--rounded m-badge--metal"
                >Closed</span>
                <span
                  v-if="snapshotCurrentData[0].snapshot_details.snapshot_editable == 1"
                  class="m-badge m-badge--wide m-badge--rounded m-badge--info"
              >Open</span>-->

              <!-- <span
                      v-if="item.accruals.length"
                      class="btn btn-outline-success btn-sm"
                      @click="createNextAccrualMonth()"
                    >Add</span>
                    <span
                      v-if="item.accruals.length"
                      class="btn btn-outline-danger btn-sm"
                      @click="accrualDeleteVisible = true"
              >Remove</span>-->
              <!-- <el-button type="text" class="btn btn-outline-danger btn-sm" v-if="item.accruals.length" @click="accrualDeleteVisible = true">Remove</el-button> -->
              <!-- </h3>
              <i class="el-icon-arrow-right" @click="showNextData()"></i>-->

              <!-- </swiper-slide>
                <div class="swiper-button-prev arrow-fix" slot="button-prev"></div>
                <div class="swiper-button-next arrow-fix" slot="button-next"></div>
              </swiper>-->
              <el-table
                class="snapshot-table"
                id="snapshot-table"
                border
                :data="snapshotCurrentData[0].accruals"
                style="width: 100%; min-height: 500px"
                :row-class-name="tableRowClassName"
                :key="snapshotTableKey"
                v-loading="loading"
                show-summary
                :summary-method="getSummaries"
              >
                <el-table-column
                  align="left"
                  prop="month"
                  label="Month"
                  min-width="60"
                  width="'100%'"
                >
                  <template slot-scope="scope">
                    <span>{{scope.row.interval_start | strToDate('YYYY-MM-DD') | date('MMM YYYY')}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  align="right"
                  prop="hours"
                  label="Hours"
                  min-width="50"
                  width="'100%'"
                  class="snapshot-hours-col"
                >
                  <template slot-scope="scope">
                    <div class="editable-cell">
                      <el-input-number
                        placeholder="hours"
                        class="snapshot-hours"
                        v-model="scope.row.hours"
                        :min="0"
                        :precision="2"
                        :controls="false"
                        controls-position="right"
                        :disabled="!updatePermission || !scope.row.active"
                        @change.native="updateAccrual(scope.row.id, scope.row.interval_start, scope.row.hours, $event, 'hourField')"
                        label="Hours"
                        size="mini"
                      ></el-input-number>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  align="right"
                  prop="completion"
                  label="% Completion"
                  min-width="70"
                  width="'100%'"
                >
                  <template
                    slot-scope="scope"
                  >{{ (scope.row.revn ? parseFloat(scope.row.revn) : 0 ) | percentage(snapshotCurrentData[0].accruals[snapshotCurrentData[0].accruals.length-1].revenueCummulative, 2)}}</template>
                </el-table-column>
                <el-table-column
                  align="right"
                  prop="revenue"
                  label="Revenue: Actual/Forecasted"
                  min-width="100"
                  width="'100%'"
                >
                  <template slot-scope="scope">
                    <!-- 
                        v-currency="{currency: 'USD', locale: 'en-US','auto-decimal-mode':true}" -->
                    <div class="editable-cell">
                      <el-input
                        type="text"
                        v-model="scope.row.revenue"
                        :trigger-on-focus="false"
                        @keyup.native="checkForMaxValue(scope.row.revenue, $event)"
                        placeholder="Revenue"
                        :min="0"
                        :disabled="!updatePermission || !scope.row.active"
                        @change.native="updateAccrual(scope.row.id, scope.row.interval_start, scope.row.revenue, $event, 'revenueField')"
                        label="Revenue"
                        size="mini"
                        v-bind:class="{
                            'positive': scope.row.revenueDifference === 'up',
                            'negative': scope.row.revenueDifference === 'down'
                          }"
                      >
                        <template slot="prepend">$</template>
                      </el-input>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  align="right"
                  prop="revenueCummulative"
                  label="Revenue Cummulative"
                  min-width="100"
                  width="'100%'"
                >
                  <template slot-scope="scope" class="cumulative-field">
                    <span>{{scope.row.revenueCummulative | currency}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  align="right"
                  prop="invoice"
                  label="Invoice Amount"
                  min-width="80"
                  width="'100%'"
                >
                  <template slot-scope="scope">
                    <a
                      class="m-link m--font-bold"
                      @click="(updatePermission && scope.row.active) && openInvoiceEditModal(scope.row.invoices, scope.row.id)"
                      v-bind:class="{'amount-link': scope.row.active === true}"
                    >
                      <span
                        v-if="!scope.row.invoices.length"
                        v-bind:class="{
                            'positive': scope.row.invoiceDifference === 'up',
                            'negative': scope.row.invoiceDifference === 'down'
                          }"
                      >{{ scope.row.invoices | array_of_objects_sum('value') | currency }}</span>
                      <el-popover trigger="hover" placement="left" v-else>
                        <ul
                          class="invoice-container"
                          v-for="(invoice, index) in scope.row.invoices"
                          :key="index"
                        >
                          <li>Date: {{invoice.invoice_date | bit_date_format}}</li>
                          <li>Amount: {{invoice.value | currency}}</li>
                        </ul>
                        <span
                          slot="reference"
                          v-bind:class="{
                                'positive': scope.row.invoiceDifference === 'up',
                                'negative': scope.row.invoiceDifference === 'down'
                              }"
                        >{{ scope.row.totalInvoice | currency }}</span>
                      </el-popover>
                      <i v-if="updatePermission && scope.row.active" class="la la-pencil-square"></i>
                    </a>
                  </template>
                </el-table-column>
              </el-table>
            </template>
            <div v-bind:class="{'min-height-500': !snapshotCurrentData.length }"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <h3 class="chart-header">Revenue vs Invoice</h3>
            <div class="row">
              <div class="col-lg-12" v-bind:class="{ 'no-data': !lineChartData.length }">
                <line-chart
                  :data="lineChartData"
                  :collection="['Revenue', 'Invoice']"
                  :xAxisKey="'Month'"
                  :yAxisLabel="'Amount($)'"
                  :scrollBar="false"
                  :balloonTextValuePrefix="'$'"
                  :showBalloonText="true"
                ></line-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <invoice-edit-dialog
      @invoiceUpdated="updateInvoice"
      :accrualId="accrualId"
      :open.sync="editInvoice"
      :currentInvoices="editInvoiceObject"
    ></invoice-edit-dialog>
  </div>
</template>

<script src="./snapshot-panel.js"></script>
<style scoped src="./snapshot-panel.css"></style>
<style scoped>
.swiper-button-prev,
.swiper-button-next {
  top: 4%;
  cursor: pointer;
}
</style>
