#talent-need-analysis-container {
  /*Overriding default 5% to avoid cutting off the text*/
}
#talent-need-analysis-container .tab-pane .portlet {
  margin: 0px 20px;
}
#talent-need-analysis-container .el-date-editor, #talent-need-analysis-container .el-select {
  width: 100%;
}
#talent-need-analysis-container .el-date-editor .el-range-separator {
  width: 10%;
}

