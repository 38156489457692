.no-data:after {
  display: flex;
  position: absolute;
  content: "- No Data -";
  background: rgba(255, 255, 255, .9);
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 3 !important;
  align-items: center;
  justify-content: center;
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  margin-top: -30px;
}

.date-picker >>> .el-date-editor.el-input,
.date-picker >>> .el-date-editor.el-input__inner {
  width: 100%;
}

.snapshot-week-table >>> .el-table__row .m-badge {
  font-size: 1rem;
}