.sort_link.fa-caret-up,
.sort_link.fa-caret-down {
    color: #5867dd !important;
}

.form-fix>>>.el-input--medium .el-input__inner {
    height: calc(2.2125rem + 2px);
    font-family: sans-serif, Arial;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.form-fix.el-date-editor.el-input {
    width: 100% !important;
}

.align-right {
    text-align: right;
}


/* .list-item-fix >>> .el-select-dropdown__item {
  overflow: unset;
  height: auto;
} */


/* custom dropdown css start */

.kt-subdropdown {
    background: #f5f5f5;
    padding: 15px 20px;
}

.kt-subdropdown .kt-subdropdown__title {
    font-size: 14px;
    color: #4a90e2;
    margin: 0 0 10px;
}

.kt-subdropdown .form-label {
    color: #646c9b !important;
    font-size: 12px !important;
    margin: 0 0 10px !important;
}

.kt-subdropdown .radio-form {
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px;
}

.kt-subdropdown .radio-form .radio-panel {
    font-size: 12px;
    color: #646c9b;
    display: flex;
}

.kt-subdropdown .radio-form .radio-panel span {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.kt-subdropdown .radio-form .radio-panel span input {
    margin: -5px 9px 0 0;
}

.kt-subdropdown .radio-form .form-label {
    margin: 0 !important;
}

.kt-subdropdown .text-form {
    width: 100%;
    margin: 0 0 10px;
}

.kt-subdropdown .text-form input {
    width: 100%;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #e3e5ed;
    color: #000;
    padding: 0 10px;
    outline: 0;
}

.kt-subheader {
    background: none !important;
    margin: 0 0 10px !important;
}

.kt-subdropdown .button-panel {
    text-align: end;
}


/* custom dropdown css end */