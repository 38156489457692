.tab-pane .portlet {
  margin: 0px 20px;
}
.el-date-editor, .el-select {
  width: 100%;
}
/*Overriding default 5% to avoid cutting off the text*/
.el-date-editor .el-range-separator {
  width: 10%;
}
/*Adding extra margin for switch*/
#period_toggler {
  margin-top: 10px;
}
/*to fix the active color for gantt-controls radio buttons*/
.gantt-controls .el-radio-button.el-radio-button--small.is-active > .el-radio-button__inner {
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
  box-shadow: -1px 0 0 0 #409eff;
}
.timeline-select {
  width: 100px;
  margin-right: 10px;
}
/*BEGIN: to override element ui css*/
.gantt-controls .radio, .gantt-controls .checkbox {
  margin-top: 0px;
  margin-bottom: 0px;
}
#fullscreen-body .portlet-body {
  overflow: visible !important;
}
#fullscreen-body .viewport_height {
  height: 100vh;
}
#fullscreen-body.m-portlet--fullscreen .viewport_height {
  height: calc(100vh - 220px);
}
#hot-preview{
  position: relative;
  z-index: 0;
}