tr.project-table-header-row>th {
    text-align: center !important;
}

.is-zero {
    color: #ccc;
}

.is-negative {
    color: #f56c6c;
}

.is-positive {
    color: #67c23a;
}

/*Override el-table-column header bg-color with important*/
.el-table thead.is-group th.bg-info {
    background-color: #e0ebf9 !important;
}

/*Adding extra margin for switch*/
#dollar_toggler {
    margin-top: 10px;
}

div.forecast-bicheck {
    border-bottom: none !important;
    margin: 10px 0px -10px 0px;
}
.input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.70rem 1.15rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

g[aria-labelledby$="-title"][filter^="url(\"#filter-id-"] {
    opacity: 0;
    display: none;
    visibility: hidden;
}

.no-data:after {
    display: flex;
    position: absolute;
    content: "- No Data -";
    background: rgba(255, 255, 255, .9);
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 3 !important;
    align-items: center;
    justify-content: center;
}

.left-align {
  padding: 0 !important;  
}
.view-option-popup {
    cursor: pointer;
}

.copy-btn {
  width: 33px;
  height: 33px;
  margin-right: 12px;
}

.copy-btn i {
  font-size: 1.3rem;
  color: #36a3f7;
}
.copy-btn:hover i {
  color: #ffffff;
}
.select-filter__wrap .el-tag--small{
    display: flex;
    align-items: center;
}

.select-filter__wrap .el-select__tags-text{
    max-width: 196px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.el-table .el-table__header, .el-table .el-table__body {
    width: 100%;
}

/*#threshold_value{
    width: 80%;
}
.input-group-append.forecast {
    width: 20%;
}*/

.forecastReportScrollbar div::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
/*滚动条的轨道*/
.forecastReportScrollbar div::-webkit-scrollbar-track {
    background-color: #FFFFFF;
}
/*滚动条里面的小方块，能向上向下移动*/
.forecastReportScrollbar div::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    border-radius: 5px;
    border: 1px solid #F1F1F1;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
}
.forecastReportScrollbar div::-webkit-scrollbar-thumb:hover {
    background-color: #A8A8A8;
}
.forecastReportScrollbar div::-webkit-scrollbar-thumb:active {
    background-color: #787878;
}
/*边角，即两个滚动条的交汇处*/
.forecastReportScrollbar div::-webkit-scrollbar-corner {
    background-color: #FFFFFF;
}

.role-dropdown {
    margin-top: -70px !important; 
  }



.custom-gap-radio {
    margin-left: 40px !important; 
  }
  
.custom-line-gap {
    margin-bottom: 10px !important;
  }
  
  .custom-gap-radio {
    margin-left: 40px !important;
    margin-top: -40px !important;
  }
  
  .custom-line-gap {
    margin-bottom: 10px !important; 
    margin-top: -20px !important; 
  }

.custom-gap-all{
    margin-top: 30px !important;
}

.el-select .el-input__icon.el-icon-circle-close {
    color: #000 !important;
  }

.name-custom-gap{
    margin-top: -20px !important;
}

.custom-gap-radio-for-billable {
margin-top: 8px !important; /* Adjust this value to lower the radio buttons */
}