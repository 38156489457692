.attachments-modal >>> .el-dialog__body {
  padding-bottom: 0px;
}

.upload-attachments >>> .el-upload,
.upload-attachments >>> .el-upload-dragger {
  width: 100%;
  height: 200px;
}
/* .upload-attachments >>> .el-upload-list__item {
  width: 50%;
  display: inline-block;
} */